* {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eaf7fe; /* Soft blue background */
}

.container {
  max-width: 600px;
  margin: auto;
  padding: 0 1rem;
  background-image: linear-gradient(45deg, #eaf7fe, #fffcea); /* Light gradient background */
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); /* Adding a subtle shadow */
  padding-bottom: 2rem;
}

h1 {
  margin-top: 1rem;
  text-align: center;
  color: #333;
}

.top {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

input {
  border: none;
  outline: none;
  width: 400px;
  padding: 0.5rem;
  border-bottom: 2px solid #84a9ac; /* Changed to a more muted color */
}

.add {
  background-color: #84a9ac; /* Muted teal */
  color: #fff;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.3s; /* Smooth hover transition */
}

.add:hover {
  background-color: #5c7a89; /* Darker shade on hover */
}

.todo {
  position: relative;
  margin-top: 1rem;
  background-color: #ffd6a5; /* Soft peach color */
  color: #333;
  padding: 1.5rem 3rem;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Adding a subtle shadow */
}

.icons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  display: flex;
  gap: 0.5rem;
}

.icon {
  cursor: pointer;
  font-size: 20px;
  color: #84a9ac;
}

.icon:hover {
  color: #5c7a89; /* Darker shade on hover */
}


.firework {
    position: fixed;
    top: calc(50% - 250px);
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    animation: explode 1s forwards, rise 1s forwards;
    z-index: 1000;
}

@keyframes explode {
    from {
        width: 20px;
        height: 20px;
        opacity: 1;
    }
    to {
        width: 500px;  /* Bigger explosion */
        height: 500px;
        opacity: 0;
    }
}

@keyframes rise {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-200px); /* Increased rising height */
    }
}
